.videoCategory:hover > .overlay {
  display: block;
}
.videoCategory {
  position: relative;
}

.overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  text-align: center;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 30px;
}

.overlayPlay {
  position: absolute;
  top: 38%;
  left: 38%;
}

.imgPlay:hover {
  opacity: 0.9;
  -moz-opacity: 0.9;
  filter: alpha(opacity=90) black;
  -khtml-opacity: 0.9;
  cursor: pointer;
}

.listVideoCategory {
  float: left;
  display: flex;
  flex-direction: column;
  width: 255px;
  height: 300px;
  list-style: none;
  margin: 0 30px 30px 0;
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
}

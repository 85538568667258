.galleryCategory {
  display: grid;
  max-width: calc(100vw - 48px);
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 16px;
  margin-top: 80px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
}

/*
   * Стили компонента galleryCategory
   */
.galleryCategoryItem {
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: 350px;
}

.galleryCategoryItem-image {
  width: 100%;
  height: 260px;
  object-fit: cover;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* .ImageGalleryItem-image:hover {
  transform: scale(1.03);
  cursor: zoom-in;
} */

.listCategoryGallery {
  text-align: center;
  /* margin-top: -25px; */
}

/* .listCategoryGallery ul {
  margin: 0;
} */

.listCategoryGallery ul li {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.9em;
}
.listCategoryGallery ul li:first-child {
  margin-left: 0;
}

.listCategoryGallery ul li a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 3.4em;
  line-height: 3.8em;
  padding: 0 0.5em 0 0.5em;
  outline: 0;
  color: #266889;
  font-size: 20px;
}

.languageContent {
  display: flex;
  position: absolute;
  margin-top: 25px;
}
.languageList {
  width: 30px;
  padding-left: 15px;
  display: flex;
  align-items: start;
  cursor: pointer;
}

.listHeader {
  text-align: right;
  /* margin-top: -25px; */
}

.listHeader ul {
  margin: 0;
}

.listHeader ul li {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.9em;
}
.listHeader ul li:first-child {
  margin-left: 0;
}

.listHeader ul li a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 3.4em;
  line-height: 3.8em;
  padding: 0 0.5em 0 0.5em;
  outline: 0;
  color: #266889;
  font-size: 20px;
}

.icon::before {
  font-size: 24px;
}

@media screen and (max-width: 950px) {
  .listHeader ul li a {
    font-size: 0px;
    padding: 6px 0 0px 10px !important;
  }
  .languageContent {
    margin-top: 10px;
  }
  .listHeader ul li:last-child {
    margin-right: 5px;
  }
}

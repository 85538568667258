@media screen and (max-width: 736px) {
  .contentHomeTitle {
    font-weight: bold;
    font-size: 37px;
    text-shadow: -5px 3px #505050;
  }
  .contentHomeAuthor {
    font-size: 20px;
    text-shadow: -3px 1px #505050;
  }
}

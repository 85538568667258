@media screen and (max-width: 768px) {
  .contentAbout {
    width: 45%;
  }
}

@media screen and (max-width: 425px) {
  .contentAbout {
    width: 85%;
    margin-left: 25px;
    padding: 0 1.25em 4.5em 1.25em;
  }
}

.aboutBackground {
  background: url(../../image/pages/overlay.png),
    url(../../image/pages/about/two.jpg);
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
  height: 100%;
  width: 100%;
}
.contentAbout {
  background: rgba(255, 255, 255, 0.85);
  margin: auto;
  padding: 1.5em 1.25em 6.5em 1.25em;
  position: relative;
  width: 35%;
  text-align: justify;
}
.textAbout {
  padding-top: 10px;
  padding-bottom: 10px;
}

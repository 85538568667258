@media screen and (max-width: 768px) {
  .contentContact {
    width: 45%;
    margin-top: 70px;
    background: rgba(255, 255, 255, 0.6);
  }
  .contactBackground {
    background-position: right;
  }
}
@media screen and (max-width: 425px) {
  .contentContact {
    width: 85%;
    margin-left: 25px;
    padding: 0 1.25em;
  }
}

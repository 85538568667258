.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 12000;
}

.Modal {
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 24px);
}

.description {
  background: white;
  margin-left: 50px;
  height: max-content;
}

.modalContent {
  display: flex;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  outline: none;
  background-image: url('../../image/gallery/icon-close.svg');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

.closeButton:hover,
.closeButton:focus {
  background-color: rgba(0, 0, 0, 0.5);
}

/*modal from video*/

.contentModalVideo {
  flex-direction: column;
  justify-content: center;
  background: white;
  width: 600px;
  padding: 10px;
}

@media screen and (max-width: 736px) {
  .NFTitle {
    font-size: 50px;
  }

  .NFtext1 {
    font-size: 25px;
  }

  .NFtext2 {
    margin-top: 20px;
    font-size: 15px;
  }
}

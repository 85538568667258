header {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  background: #dee5e7;
  box-shadow: 0 0 0.15em 0 rgba(0, 0, 0, 0.1);
}
.columnLeft {
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: inline-block;
}

.contentGeneral {
  display: table;
  position: absolute;
  padding-top: 6em !important;
}
